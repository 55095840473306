import React, {useState} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import FrontPage from './pages/FrontPage/FrontPage';
import LoginPage from './pages/LoginPage/LoginPage';
import SeatingPage from './pages/SeatingPage/SeatingPage';
import ToastmasterPage from './pages/ToastmasterPage/ToastmasterPage';
import ForloverPage from './pages/ForloverPage/ForloverPage';
import InformationPage from './pages/InformationPage/InformationPage';

function App() {
  const getIsAuthenticated = () => {
    return sessionStorage.getItem('isAuthenticated');
  };

  const [isAuthenticated, setIsAuthenticated] = useState(getIsAuthenticated);

  if (!isAuthenticated) {
    return <LoginPage setIsAuthenticated={setIsAuthenticated} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<FrontPage />} />
        <Route exact path="/bordkart"
          element={<SeatingPage />} />
        <Route exact path="/toastmastere"
          element={<ToastmasterPage />} />
        <Route exact path="/forlovere"
          element={<ForloverPage />} />
        <Route exact path="/informasjon"
          element={<InformationPage />} />
        <Route path="*" element={<FrontPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
