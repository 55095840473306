/* eslint-disable max-len */
import React, {useState} from 'react';
import Header from '../../components/header/Header';
import {useMediaQuery} from 'react-responsive';

import './SeatingPage.css';
import Footer from '../../components/footer/Footer';

import seatingData from '../../assets/data/seatingData.json';


function SeatingPage() {
  const isMobile = useMediaQuery({query: '(max-width: 800px)'});

  const title = 'Bordkart';

  const [isVariationOne, setIsVariationOne] = useState(false);

  const toggleVariation = () => {
    setIsVariationOne(!isVariationOne);
  };

  const renderSortedByTable = () => {
    return (
      <div>
        {Object.keys(seatingData).map((table, index) => (
          <div key={index}>
            <h2>{table}</h2>
            {seatingData[table].map((name, index) => (
              <p key={index}>{name}</p>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const renderSortedByName = () => {
    let allNames = [];
    Object.keys(seatingData).forEach((table) => {
      allNames = allNames.concat(seatingData[table]);
    });
    const sortedNames = allNames.sort();

    const namesByFirstLetter = {};
    sortedNames.forEach((name) => {
      const firstLetter = name[0].toUpperCase();
      if (!namesByFirstLetter[firstLetter]) {
        namesByFirstLetter[firstLetter] = [];
      }
      namesByFirstLetter[firstLetter].push(name);
    });

    return (
      <div>
        {Object.keys(namesByFirstLetter).map((letter, index) => (
          <div key={index} style={{'textAlign': 'left'}}>
            <h2>{letter}</h2>
            {namesByFirstLetter[letter].map((name, index) => (
              <p key={index}>{`${name} – ${getTableNumber(name)}`}</p>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const getTableNumber = (name) => {
    for (const table in seatingData) {
      if (seatingData[table].includes(name)) {
        return table;
      }
    }
    return 'Unknown';
  };

  const getButtonText = () => {
    return isVariationOne ? 'Sortér etter navn' : 'Sortér etter bord';
  };

  return (
    <>
      <Header />
      <div className='content'>
        {isMobile ?
          // Mobile
          <>
            <div className='fixed-width-center'>
              <div className='text-big'>
                {title}
              </div>
              <button onClick={toggleVariation}>{getButtonText()}</button>
              {isVariationOne ? renderSortedByTable() : renderSortedByName()}
            </div>
          </> :
          // Desktop
          <>
            <div className='fixed-width-center'>
              <div className='text-big'>
                {title}
              </div>
              <button onClick={toggleVariation}>{getButtonText()}</button>
              {isVariationOne ? renderSortedByTable() : renderSortedByName()}
            </div>
          </>
        }
      </div >
      <Footer />
    </>
  );
}

export default SeatingPage;
