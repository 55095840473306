/* eslint-disable max-len */
import React from 'react';
import Header from '../../components/header/Header';
import {useMediaQuery} from 'react-responsive';
import './ToastmasterPage.css';

import Footer from '../../components/footer/Footer';

import salibaImg from '../../assets/pictures/saliba.jpg';
import jørundImg from '../../assets/pictures/joerund.jpg';

function ToastmasterPage() {
  const isMobile = useMediaQuery({query: '(max-width: 800px)'});

  const deadlineText = <>
    Det er ikke rom for taler utenom det som på forhånd er avtalt med toastmasterne.
    <br />
    Frist for å melde inn tale er lørdag 20. april.
  </>;

  const jørundText =
    <>
      <h2>Jørund</h2>
      Jørund har kjent Lise siden 2013 og er en venn fra politikken. De jobbet sammen i byrådet i Bergen og arbeidsutvalget i Hordaland Unge Høyre. Jørund er gift med Kaja og bor i Oslo.
      <a href='mailto:jtgjesvik@gmail.com' target='_blank' rel="noreferrer">&#128231; jtgjesvik@gmail.com</a>
    </>
    ;

  const salibaText =
    <>
      <h2>Saliba</h2>
      Saliba har kjent Lise siden 2014 og er en venn fra politikken. De jobbet sammen i byrådet i Bergen og arbeidsutvalget i Hordaland Unge Høyre. Saliba er gift med Mari, har to skjønne gutter og bor i Oslo.
      <br />
      <br />
      <a href='mailto:salibakorkunc@gmail.com' target='_blank' rel="noreferrer">&#128231; salibakorkunc@gmail.com</a>
    </>
    ;

  return (
    <>
      <Header />
      <div className='content'>
        {isMobile ?
          // Mobile
          <>
            <div className='profiles-mobile'>
              <div className='text-big'>
                Toastmastere
              </div>
              <i style={{'max-width': '90%'}}>{deadlineText}</i>
              <div>
                <img src={jørundImg} />
              </div>
              {jørundText}
              <div>
                <img src={salibaImg} />
              </div>
              {salibaText}

            </div>
          </> :
          // Desktop
          <>
            <div className='fixed-width-center'>
              <div className='text-big'>
                Toastmastere
              </div>
              <i style={{'max-width': '90%'}}>{deadlineText}</i>
              <div className='one-third-image'>
                <div>
                  <img src={jørundImg} />
                </div>
                <div className='two-thirds-text-right'>
                  {jørundText}
                </div>
              </div>
              <div className='one-third-image'>
                <div className='two-thirds-text-left'>
                  {salibaText}
                </div>
                <div>
                  <img src={salibaImg} />
                </div>
              </div>
            </div>
          </>
        }
      </div >
      <Footer />
    </>
  );
}

export default ToastmasterPage;
