import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// const isHTTPS = window.location.protocol === 'https:';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (true) {
// if (isHTTPS) {
  root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
  );
} else {
  window.location.href = 'https://www.11mai2024.no';
}
