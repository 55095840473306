import React, {useEffect, useState} from 'react';
import Header from '../../components/header/Header';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-router-dom';

import './FrontPage.css';
import Footer from '../../components/footer/Footer';

import backgroundImg from '../../assets/pictures/engaged.jpeg';

import ringsIcon from '../../assets/icons/rings.png';
import foodIcon from '../../assets/icons/food.png';
import bowTieIcon from '../../assets/icons/bow-tie.png';
// import giftIcon from '../../assets/icons/gift.png';
import churchIcon from '../../assets/icons/church.png';
import sunIcon from '../../assets/icons/sun.png';
import champagneIcon from '../../assets/icons/champagne.png';
import cakeIcon from '../../assets/icons/wedding-cake.png';
import danceIcon from '../../assets/icons/dance.png';
import coupleIcon from '../../assets/icons/couple.png';
import cameraIcon from '../../assets/icons/camera.png';


function FrontPage() {
  const isMobile = useMediaQuery({query: '(max-width: 800px)'});

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const targetTime = new Date('2024-05-11T17:00:00+02:00');
    const currentTime = new Date();
    const timeDifference = targetTime.getTime() - currentTime.getTime();

    if (timeDifference > 0) {
      const timer = setTimeout(() => {
        setShowContent(false);
      }, timeDifference);
      return () => clearTimeout(timer);
    } else {
      setShowContent(true);
    }
  }, []);


  const merInfoLink = <Link
    to="/informasjon"
    style={{
      fontStyle: 'italic',
      margin: 0,
      textDecoration: 'none',
    }}>
    &quot;Mer informasjon&quot;
  </Link>;

  const bordkartLink =
    <>
      {showContent && (
        <Link
          to="/bordkart"
          style={{
            fontStyle: 'italic',
            margin: 0,
            textDecoration: 'none',
          }}>
          Bordkart (link)
        </Link>
      )}
    </>;


  const text =
    <div className='text'>
      <div className='text-big'>11. mai 2024</div>
      <div className='text-medium'>
        <img src={bowTieIcon} />
        <div>
          Kleskode:
        </div>
        <div>
          Black tie optional / Mørk dress
        </div>
        <div>
          Lang/midi kjole
        </div>
      </div>
      <div className='text-medium'>
        {/* <a href='/informasjon' style={{margin: 0}}>
          <img src={giftIcon} />
        </a> */}
        <div>
          Se {merInfoLink} for <br />gaveliste og praktiske detaljer
        </div>
      </div>

      &mdash;

      <h3>Program for dagen</h3>
      <div className='text-medium'>
        <img src={churchIcon} />
        Oppmøte i Holmenkollen kapell <br />
        kl. 12:30 &mdash; 12:45
      </div>
      <div className='text-medium'>
        <img src={ringsIcon} />
        Vielse <br />
        kl. 13:00
      </div>
      <div className='text-medium' style={{'color': 'gray'}}>
        <img src={sunIcon} />
        Pause i programmet<br />
        Ca. kl. 14:00 &mdash; 16:30
        <i style={{
          'fontSize': '1rem',
          'margin-top': '10px',
        }} >Tips: Se {merInfoLink}</i>

        <i></i>
      </div>
      <div className='text-medium'>
        <img src={champagneIcon} />
        Ankomst Lysebu <br />
        kl. 16:30
      </div>
      <div className='text-medium'>
        <img src={coupleIcon} />
        Brudeparet ankommer Lysebu <br />
        kl. 17:00
      </div>
      <div className='text-medium'>
        <img src={cameraIcon} />
        Fellesbilder i hagen <br />
        kl. 17:20
      </div>
      <div className='text-medium'>
        <img src={foodIcon} />
        Middag <br />
        kl. 18:00 <br />
        <i style={{
          'fontSize': '1rem',
          'margin-top': '10px',
        }} >{bordkartLink}</i>
      </div>
      <div className='text-medium'>
        <img src={cakeIcon} />
        Kaffe og kake <br />
        kl. 22:00
      </div>
      <div className='text-medium'>
        <img src={danceIcon} />
        Brudedans og fest <br />
        kl. 22:30 &mdash; 02:00
      </div>
    </div>
    ;

  return (
    <>
      <Header />
      <div className='content'>
        {isMobile ?
          // Mobile
          <>
            {text}
          </> :
          // Desktop
          <>
            <div className='image-container'>
              <img src={backgroundImg} alt='Foto: Fotograf Håndlykken' />
            </div>
            <div style={{marginLeft: '50vw'}}>
              {text}</div>
          </>
        }
      </div >
      <Footer />
    </>
  );
}

export default FrontPage;
