import React, {useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-router-dom';
import './Header.css';
import Hamburger from '../hamburger/Hamburger';

const Header = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const targetTime = new Date('2024-05-11T17:00:00+02:00');
    const currentTime = new Date();
    const timeDifference = targetTime.getTime() - currentTime.getTime();

    if (timeDifference > 0) {
      const timer = setTimeout(() => {
        setShowContent(false);
      }, timeDifference);
      return () => clearTimeout(timer);
    } else {
      setShowContent(true);
    }
  }, []);

  const [isHidden, setIsHidden] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsHidden(prevScrollPos < currentScrollPos && currentScrollPos > 80);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const isMobile = useMediaQuery({query: '(max-width: 800px)'});
  const [dropdown, setDropdown] = useState(false);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <>
      {isMobile ?
        <>
          {dropdown ?
            <>
              <header >
                <div className='header-left'>
                  <Link to="/" onClick={toggleDropdown}>Lise og Sander</Link>
                </div>
                <div className='header-right'>
                  <Hamburger
                    isOpen={dropdown}
                    onHamburgerClick={toggleDropdown}
                  />
                </div>
              </header>
              <div className='dropdown-menu'>
                {showContent && (
                  <Link to="/bordkart" onClick={toggleDropdown}>
                    Bordkart
                  </Link>
                )}
                <Link to="/toastmastere" onClick={toggleDropdown}>
                  Toastmastere
                </Link>
                <Link to="/forlovere" onClick={toggleDropdown}>Forlovere</Link>
                <Link to="/informasjon" onClick={toggleDropdown}>
                  Mer informasjon
                </Link>
              </div>
            </> :
            <>
              <header className={isHidden ? 'hidden' : ''}>
                <div className='header-left'>
                  <Link to="/">Lise og Sander</Link>
                </div>
                <div className='header-right'>
                  <Hamburger
                    isOpen={dropdown}
                    onHamburgerClick={toggleDropdown}
                  />
                </div>
              </header>
            </>
          }
        </> :
        <>
          <header className={isHidden ? 'hidden' : ''}>
            <div className='header-left'>
              <Link to="/">Lise og Sander</Link>
            </div>
            <div className='header-right'>
              {showContent && (
                <Link to="/bordkart">Bordkart</Link>
              )}
              <Link to="/toastmastere">Toastmastere</Link>
              <Link to="/forlovere">Forlovere</Link>
              <Link to="/informasjon">Mer informasjon</Link>
            </div>
          </header>
        </>
      }
    </>
  );
};

export default Header;
