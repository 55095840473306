/* eslint-disable max-len */
import React from 'react';
import Header from '../../components/header/Header';
import {useMediaQuery} from 'react-responsive';

import './InformationPage.css';
import Footer from '../../components/footer/Footer';

import churchIcon from '../../assets/icons/church.png';
import lodgeIcon from '../../assets/icons/lodge.png';
import giftIcon from '../../assets/icons/gift.png';
import footIcon from '../../assets/icons/footprint.png';
import sunIcon from '../../assets/icons/sun.png';

function InformationPage() {
  const isMobile = useMediaQuery({query: '(max-width: 800px)'});

  const holmenkollenText =
    <>
      <h2>Vielse i Holmenkollen kapell</h2>
      Barn er velkommen i vielsen, men bryllupsfesten er kun for voksne.
      <br />
      <br />
      Brudeparet ønsker at mobiltelefoner og kamera legges bort før, under og like etter vielsen. Innleid profesjonell fotograf sørger for å ta gode bilder som tilgjengeliggjøres i ettertid, og to av gjestene har på forhånd blitt bedt om å filme.
      <br />
      <br />
      Brudeparet ønsker å fylle kirken forfra i tråd med tradisjon som vist under:
      <div className='seating'>
        <div className='altar'>Alter <br />&mdash;</div>
        <div className='sides'>
          <div className='side'>
            <div className='bench'>Brudens familie</div>
            <div className='bench'>Brudens familie</div>
            <div className='bench'>Brudens venner</div>
            <div className='bench'>Brudens venner</div>
            <div className='bench'>...</div>
          </div>
          <div className='side'>
            <div className='bench'>Brudgommens familie</div>
            <div className='bench'>Brudgommens familie</div>
            <div className='bench'>Brudgommens venner</div>
            <div className='bench'>Brudgommens venner</div>
            <div className='bench'>...</div>
          </div>
        </div>
      </div>
    </>
    ;

  const transportText =
    <>
      <h2>Veibeskrivelse og parkering</h2>
      <div className='consecutive-links'>
        <a href='https://goo.gl/maps/MBiqUc25Szz7QE8e6' target='_blank' rel="noreferrer">&#128205; Holmenkollen kapell, 0791 Oslo (link til kart)</a>
        <a href='https://goo.gl/maps/4HY3ZiXovqZ1KYcY8' target='_blank' rel="noreferrer">&#128205; Lysebuveien 12, 0790 Oslo (link til kart)</a>
      </div>
      <h3>Kollektivt</h3>
      Holmenkollen kapell er omtrent 15 minutter gange opp fra Holmenkollen T-banestopp (linje 1 Frognerseteren fra sentrum). Se Ruter.no for reisetider (f.eks. 11:40 eller 11:55 fra Nationaltheatret T)
      <br /><br />
      Lysebu hotell ligger omntrent tre kilometer fra Holmenkollen kapell. Se forslag til aktiviteter (avsnitt under) mellom vielse og middag, samt foreslåtte t-baneavganger. Nærmeste t-banestopp til Lysbeu er Voksenkollen. Derfra går man opp Voksenkollveien og inn Lysebuveien (800 m).
      <h3>Bil</h3>
      Holmenkollen kapell ligger til venstre for Holkmenkollveien like etter Holmenkollen Nasjonalanlegg i retning fra Oslo sentrum.
      Parkering på platåene til høyre for oppkjørselen til kapellet og eventuelt til høyre for Holmenkollveien.
      <br /><br />
      For å komme til Lysbeu går bilveien videre opp Holkmenkollveien, til venstre ved Frognerseteren inn på Voksenkollveien og deretter til høyre inn Lysebuveien.
      Parkering ved hotellet til kr 30 per time, eller kr 160 per døgn for hotellets gjester. Lading for el-bil koster kr 200.
    </>
    ;


  const activityText = <>
    <h2 id="activities">Aktiviteter mellom vielse og bryllupsfest</h2>
    I tidsrommet mellom vielse og velkomst på Lysebu kan vi anbefale:
    <ul style={{'textAlign': 'left'}}>
      <li>Lunsj/servering på Holmenkollen restaurant &mdash; egner seg godt for deg uten bil. 10 min gange fra Holmenkollen kapell og 7 min gange fra restauranten til Holmenkollen T-banestopp. Anbefaler å ta T-banen fra Holmenkollen til Voksenkollen, enten kl. 16:03 eller kl. 16:18</li>
      <li>Lunsj/servering på Frognerseteren &mdash; egner seg best for deg med bil eller gode sko. Tar ca. 30 min å gå fra kapellet til Frognerseteren og ca. 25 min å gå fra Frognerseteren til Lysebu. Med bil tar det ca 5 min.</li>
      <li>Besøke Roseslottet like ovenfor Frognerseteren &mdash; egner seg best for deg med bil, men det er også mulig å ta T-banen ett stopp kl. 16:13 fra Frognerseteren (retning sentrum) som er et stopp like ved Roseslottet</li>
      <li>Spasertur fra kapellet til Lysebu (med gode sko)</li>
      <li>Besøke Holmenkollen Nasjonalanlegg</li>
    </ul>
  </>;

  const lysebuText =
    <>
      <h2>Overnatting på Lysebu</h2>
      <i>Overnatting kan bookes via resepsjon@lysebu.no eller telefon 21 51 10 00.</i>
    </>
    ;


  const giftText =
    <>
      <h2>Gaveliste</h2>
      Vi setter veldig stor pris på at dere ønsker å feire bryllupsdagen vår med oss og forventer ingen gaver.
      Om dere likevel vil gi noe, har vi samlet noen ønsker hos Backe i Grensen og Illums Bolighus.
      <br />
      <br />
      Backe i Grensen tilbyr å oppbevare gavene frem til bryllupsdagen og kjøre dem til gavebordet på Lysebu.
      Skulle dere kjøpe noe fra listene fra en annen forhandler eller glemte å registrere kjøpet på listen kan dere kontakte Backe eller Illums direkte og be dem etterregistrere det dere har kjøpt.
      <br />
      <br />
      <div className='consecutive-links'>
        <a
          href='https://www.backeigrensen.no/onskelister/se-en-liste/?wlid=126861&wlkey'
          target='_blank'
          rel="noreferrer">
          &#128279; Backe i Grensen (link)
        </a>
        <a
          href='https://www.illumsbolighus.no/on/demandware.store/Sites-illums_bolighus_no-Site/nb_NO/GiftRegistry-ShowOthers?id=227b5842e9967da60920ee7bab'
          target='_blank'
          rel="noreferrer">
          &#128279; Illums Bolighus (link)
        </a>
      </div>
    </>
    ;

  const title = 'Informasjon';

  return (
    <>
      <Header />
      <div className='content'>
        {isMobile ?
          // Mobile
          <>
            <div className='profiles-mobile'>
              <div className='text-big'>
                {title}
              </div>
              <div className='image-wrapper'>
                <img src={churchIcon} />
              </div>
              {holmenkollenText}
              <div className='image-wrapper'>
                <img src={footIcon} />
              </div>
              {transportText}
              <div className='image-wrapper'>
                <img src={sunIcon} />
              </div>
              {activityText}
              <div className='image-wrapper'>
                <img src={giftIcon} />
              </div>
              {giftText}
              <div className='image-wrapper'>
                <img src={lodgeIcon} />
              </div>
              {lysebuText}
            </div>
          </> :
          // Desktop
          <>
            <div className='fixed-width-center'>
              <div className='text-big'>
                {title}
              </div>
              <div className='one-third-image'>
                <div className='image-wrapper'>
                  <img src={churchIcon} />
                </div>
                <div className='two-thirds-text-right'>
                  {holmenkollenText}
                </div>
              </div>
              <div className='one-third-image'>
                <div className='image-wrapper'>
                  <img src={footIcon} />
                </div>
                <div className='two-thirds-text-right'>
                  {transportText}
                </div>
              </div>
              <div className='one-third-image'>
                <div className='image-wrapper'>
                  <img src={sunIcon} />
                </div>
                <div className='two-thirds-text-right'>
                  {activityText}
                </div>
              </div>
              <div className='one-third-image'>
                <div className='image-wrapper'>
                  <img src={giftIcon} />
                </div>
                <div className='two-thirds-text-right'>
                  {giftText}
                </div>
              </div>
              <div className='one-third-image'>
                <div className='image-wrapper'>
                  <img src={lodgeIcon} />
                </div>
                <div className='two-thirds-text-right'>
                  {lysebuText}
                </div>
              </div>
            </div>
          </>
        }
      </div >
      <Footer />
    </>
  );
}

export default InformationPage;
