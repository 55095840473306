/* eslint-disable max-len */
import React from 'react';
import Header from '../../components/header/Header';
import {useMediaQuery} from 'react-responsive';
import './ForloverPage.css';

import Footer from '../../components/footer/Footer';

import michelleImg from '../../assets/pictures/michelle.jpg';
import johanneImg from '../../assets/pictures/johanne.jpg';
import tobiasImg from '../../assets/pictures/tobias.jpg';

function ForloverPage() {
  const isMobile = useMediaQuery({query: '(max-width: 800px)'});

  const michelleText =
    <>
      <h2>Michelle</h2>
      Michelle er en av brudens forlovere. Hun har kjent Lise siden de gikk i samme klasse på Tanks videregående skole i Bergen. Michelle er fra Eidsvåg, samme sted som brudgommen. Michelle bor i Bergen og er samboer med Jon.
    </>
    ;

  const johanneText =
    <>
      <h2>Johanne</h2>
      Johanne er en av brudens forlovere. Hun er kusinen til Lise og har vokst opp i Kristiansand. Johanne bor i Oslo og er samboer med Olav.
    </>
    ;

  const tobiasText =
    <>
      <h2>Tobias</h2>
      Tobias er brudgommens forlover. De har kjent hverandre siden høsten 2014, da de møttes på rotrening i Trondheim. Tobias er gift med Susanne og sammen har de en skjønn datter. Tobias og Susanne bor like ved brudeparet.
    </>
    ;

  return (
    <>
      <Header />
      <div className='content'>
        {isMobile ?
          // Mobile
          <>
            <div className='profiles-mobile'>
              <div className='text-big'>
                Forlovere
              </div>
              <div>
                <img src={tobiasImg} />
              </div>
              {tobiasText}
              <div>
                <img src={michelleImg} />
              </div>
              {michelleText}
              <div>
                <img src={johanneImg} />
              </div>
              {johanneText}
            </div>
          </> :
          // Desktop
          <>
            <div className='fixed-width-center'>
              <div className='text-big'>
                Forlovere
              </div>
              <div className='one-third-image'>
                <div>
                  <img src={tobiasImg} />
                </div>
                <div className='two-thirds-text-right'>
                  {tobiasText}
                </div>
              </div>
              <div className='one-third-image'>
                <div className='two-thirds-text-left'>
                  {michelleText}
                </div>
                <div>
                  <img src={michelleImg} />
                </div>
              </div>
              <div className='one-third-image'>
                <div>
                  <img src={johanneImg} />
                </div>
                <div className='two-thirds-text-right'>
                  {johanneText}
                </div>
              </div>
            </div>
          </>
        }
      </div >
      <Footer />
    </>
  );
}

export default ForloverPage;
