import React from 'react';
import './Hamburger.css';

function Hamburger({isOpen, onHamburgerClick}) {
  return (
    <div className={'hamburger' + (isOpen ? '-open' : '')}
      onClick={onHamburgerClick}>
      <div className='bar1'></div>
      <div className='bar2'></div>
      <div className='bar3'></div>
    </div>
  );
}

export default Hamburger;
