import React from 'react';
import './Footer.css';
import magnolia from '../../assets/pictures/magnolia.png';

const Footer = () => {
  return (
    <footer>
      <div className='footer-left' style={{'fontStyle': 'italic'}}>
        Foto: Fotograf Håndlykken
      </div>
      <div>
        &copy; 11. mai 2024
      </div>
      <img src={magnolia} />
    </footer>
  );
};

export default Footer;
