import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './LoginPage.css';
const bcrypt = require('bcryptjs');


function LoginPage({setIsAuthenticated}) {
  const [password, setPassword] = useState();
  const [wrongEntry, setWrongEntry] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // TODO Move authentication to backend server (https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications)
    const hash = '$2a$10$sHAKPEkdIyiK7rE8X7ncZOmL1A3AdFz9PlWQYA.3Yq0AyyoPKhKTa';
    bcrypt.compare(password, hash, (err, result) => {
      if (result) {
        setIsAuthenticated(true);
        sessionStorage.setItem('isAuthenticated', 'true');
      } else {
        setWrongEntry(true);
      }
    });
  };

  return (
    <>
      <div className='login-page'>
        <form onSubmit={handleSubmit}>
          <div className='password'>Passord:</div>
          <label>
            <input
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
                setWrongEntry(false);
              }}
            />
          </label>
          <br />
          <button type="submit">&#x1F513;</button>
        </form>
      </div>
      {wrongEntry && <div className='error-msg'>
        Feil passord, prøv igjen
      </div>}
    </>
  );
}

LoginPage.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default LoginPage;
